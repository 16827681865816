import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Pop Over`}</inlineCode>{` merupakan unsur UI yang dapat digunakan untuk memperlihatkan daftar informasi atau pilihan yang terkait dengan label induknya.`}</p>
    </PageDescription>
    <h2>{`Variant`}</h2>
    <h3>{`Dropdown`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1006px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABCElEQVQoz31Si5KDIAz0/3/ypqfWVt4PsV7VLTCiiHN1JgMmm92EpGJCIxjlaj/TPY/lvvSf56RYxcQZGE4uzcX3H6aMV7SogDCJ7kkvvp6KU3LA9IRfKq5YVrbwqoQJNO0DyjhIbWPS/UFiXNvRV6ZRNx1+bnUUknooWs4IpTIwxmIFMM8LBvdCe+/AGI++v/cM4TG33xrWxzwExrozYf6w2lfknMOyLNHsMKLvCZRSwLri7QmDaNu2mKYJ4XPjC6Qk3El9C6FlFn0y+sPbBWMbLhA2TeOrd5EwiBZTvk6T8mTFqqRzE06iNGK2oRwt59M+7LyDKXkT2e8Hpsp36JuVi5+EywX/AFdC+NnmuoRnAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/86de07ee4314aa0d740e3d64bb42cd7d/72da9/dropdown.png",
              "srcSet": ["/static/86de07ee4314aa0d740e3d64bb42cd7d/7fc1e/dropdown.png 288w", "/static/86de07ee4314aa0d740e3d64bb42cd7d/a5df1/dropdown.png 576w", "/static/86de07ee4314aa0d740e3d64bb42cd7d/72da9/dropdown.png 1006w"],
              "sizes": "(max-width: 1006px) 100vw, 1006px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Dropdown with header`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1006px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABRElEQVQoz31Si26DMAzk/79x3bqCREsSyJvQAjc7PMY6tZEsJxfnfI5dyM5Ctiabor1QGjfR7tiGqzVui7k2Co3sdnyzYgti32qXyb7OJdqVhLH6JrN1tGfsJhQ+TmfUV5Hvj0kLdSC0LpB5hJjwGCc4H2Gsh2o1Yp9wv4/QdLZkPvQYpzn7P4RHhZ220MZioIfD/QFPxJeyQiMkJZiRhkeOOX1+0xtD55EExNeErK7vI/Ka56y0aRoYYzI0kmpDMc45eB8yxsrFK0JHQd57pJRyMJdjSLHWhshGTNNEFThUVYlhGBbC+IYw/18I+eG8KxREqHeFTFheLujXpP8UqifClHpsK/YD6vq6l8xJFoUVKVu+JsQ3TWk7bsxSOnecx0SqLnd5wbjjZh2pX3/kKI7Dy56HdrPtTiiD5gmTrd39ceh/ANRD/ObNvhUMAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/5cd989e4d76dc1a5e07b83e211e43f31/72da9/dropdown-header.png",
              "srcSet": ["/static/5cd989e4d76dc1a5e07b83e211e43f31/7fc1e/dropdown-header.png 288w", "/static/5cd989e4d76dc1a5e07b83e211e43f31/a5df1/dropdown-header.png 576w", "/static/5cd989e4d76dc1a5e07b83e211e43f31/72da9/dropdown-header.png 1006w"],
              "sizes": "(max-width: 1006px) 100vw, 1006px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Infromation`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1006px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABK0lEQVQoz32Si26DMAxF9/8/OU0LNA8KIQQynne2U9bS0iFdGWzl2jnm49oEVHWLPe7K38fcnq99J3HXnmd9PCebNpI6XLSjnD8cZKOLqfD5pU6at8+GfIAMmwbzvGCaJizLAn6G9IPq2mAYEtZ1wzhybcU0z9L80fQwoadiURQolJJojEFKCeM0k2GNsiygtaa8hvdeGoauh7u+GObRa3o31tF1DR3K0dJ320W4qoaiJqyivJBKGoCGCPH9hKzMMAqrnZs0oxhiklypLRn1OFvowbARhv7OcF2FYeyTMAwhyDXHccS2bVL7d0JmyJzUjSHzSmlAP+SlKPWdGZOcs5jIOMThHcMgDJmZNjaLrmZdddukJ772r8Z8eVGnW37+l+7qHviEl1p1cvYXDnH351yXL50AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4633c152286c847743bfd5ef1d622de9/72da9/info.png",
              "srcSet": ["/static/4633c152286c847743bfd5ef1d622de9/7fc1e/info.png 288w", "/static/4633c152286c847743bfd5ef1d622de9/a5df1/info.png 576w", "/static/4633c152286c847743bfd5ef1d622de9/72da9/info.png 1006w"],
              "sizes": "(max-width: 1006px) 100vw, 1006px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
  <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1006px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABH0lEQVQoz5WR3a6EIAyE9/3f8ygIFETwP5nTsuJq4l7sxYT0Cw7T8eV8hPM97udVz4zC8MAjXpbeH9Szsx6ttmWuTBuC6ujGGmWgzIdVvaqzDKFPiIMoI49z4X1MRUMakfJU7sWUQZxSWGTVR0rCaiYrGMPJjMEwRKzrUi5rreGcRc6J2cpGEUophBAwjSPmZXk2lJN8QNcZtErz6/ndE3ko3aHjh9KRhpjJHeeobHMzvHZIR+HGeQRe80zOs6UA3w8Hi/hrNfdNJ/tiyL3EiH3fizx3KmlkXZm3bStm5IXlwqZ5+W7oOIX007Ytr5zKT7K8lsyikTuTxNZaNE3DnXMNWWr41HYxjMevD2wSyurnfOjG3J3VULefctWvrJ7/T2H4eJlGEtYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/0695139d3ec62c7f18754243e08f4aa7/72da9/info-2.png",
              "srcSet": ["/static/0695139d3ec62c7f18754243e08f4aa7/7fc1e/info-2.png 288w", "/static/0695139d3ec62c7f18754243e08f4aa7/a5df1/info-2.png 576w", "/static/0695139d3ec62c7f18754243e08f4aa7/72da9/info-2.png 1006w"],
              "sizes": "(max-width: 1006px) 100vw, 1006px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h2>{`Use Cases`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1006px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAAvUlEQVQoz41SCQ6EIBDz/9/cI6ty403cLoMxIrKqSaO0M7UMFFwapGBCZ9cpn+OKVIyb0/W/uqxhrkgoe0hIyPHZhKtIDUwoPN8lGFc7nvB4fVAxeW/LSzID03QBTdtvZqTJRbOeX358YSh9kda+yVrQM89f1D6l1A1qxgKcc0GT+oYhJVBKwxiDcZrQ9UMwpDRlVYNz7rkewzCGnZweSjoTMor5dYb0nWqnM4xP/Iy/vDbbe4/N6MiH/sj0B0pi+MWUCQlbAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/e0e54b04d5dd9df7750b56987d3a72e2/72da9/usecase-dropdown.png",
              "srcSet": ["/static/e0e54b04d5dd9df7750b56987d3a72e2/7fc1e/usecase-dropdown.png 288w", "/static/e0e54b04d5dd9df7750b56987d3a72e2/a5df1/usecase-dropdown.png 576w", "/static/e0e54b04d5dd9df7750b56987d3a72e2/72da9/usecase-dropdown.png 1006w"],
              "sizes": "(max-width: 1006px) 100vw, 1006px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
  <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1006px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAAtElEQVQoz41Siw7CIAzk/79SkznnoIW6R9yMOxnGOBxkkDSF3uXoQRXZO1JhWJDDvrjhfV3lyRLtdxG4srtYHXXBroOVPkSEsyRdqJzNIOazdQ7GEJgt5vmJda351+WB5a3Vtbu21TifT6iqCk3TYJomvJal7A3jd3OfOlEQqusaFx/DMHrReeNGygXZk7t+xMMLaLK43kzANbmIW2R5e9YkSYHiT/kfkdz4HHeYIaVmMzdqb2Sv99lzQRRhAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4af3e55e7cbf72bdded402ba13c7c07d/72da9/usecase-info.png",
              "srcSet": ["/static/4af3e55e7cbf72bdded402ba13c7c07d/7fc1e/usecase-info.png 288w", "/static/4af3e55e7cbf72bdded402ba13c7c07d/a5df1/usecase-info.png 576w", "/static/4af3e55e7cbf72bdded402ba13c7c07d/72da9/usecase-info.png 1006w"],
              "sizes": "(max-width: 1006px) 100vw, 1006px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      